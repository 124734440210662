$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$('div.alert').not('.alert-important').delay(5000).slideUp(300);
$('#flash-overlay-model').modal();

$('[data-toggle="tooltip"]').tooltip();

$('.datepicker').datepicker({
    language: 'pl-PL',
    orientation: 'bottom'
});

$('.delete-btn').click(function (e) {
    e.preventDefault();
    url = $(this).data('url');

    BootstrapDialog.confirm({
        title: 'Uwaga!',
        message: 'Czy na pewno chcesz usunąć element?',
        type: BootstrapDialog.TYPE_WARNING,
        closable: true,
        draggable: true,
        btnCancelLabel: 'Nie',
        btnOKLabel: 'Tak!',
        btnOKClass: 'btn-warning',
        callback: function (result) {
            if (result) {
                $.ajax({
                    type: 'DELETE',
                    url: url,
                    success: function (response) {
                        if (response.redirect) {
                            window.location.href = response.redirect;
                        }
                        else if (response.reload) {
                            location.reload();
                        }
                        else if (response.slide) {
                            $("div[data-element-id='" + response.slide + "'], li[data-element-id='" + response.slide + "']").slideUp('slow');
                        }
                        else {
                            // default
                        }
                    }
                });
            }
        }
    });
});
